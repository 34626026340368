import "./manage.css";
import React from "react";
import {useState, useEffect, useRef} from "react";
import {uploadDocuments} from "../../tools/imageuploader";
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import {useRecoilState, useRecoilValue} from "recoil";
import { currentimage, imagelist, historyimages } from "../../store";
import { Link, useNavigate } from "react-router-dom";


const Manage = () => {
    const navigate = useNavigate();
    const [displayedImage, setDisplayedImage] = useRecoilState(currentimage);
    const [maskImage, setmaskImage] = useState("");
    const [images, setimages] = useRecoilState(imagelist);
    const [isdalle2, setisdalle2] = useState(0);
    const [isdalle3, setisdalle3] = useState(0);
    const [his, sethis] = useRecoilState(historyimages);


    const updateImages = (newImages:any) => {   
        const images = uploadDocuments(Object.values(newImages.target.files), setDisplayedImage);   
        console.log("running"); 
        console.log(newImages);
    };

    const Input = styled('input')({
        display: 'none',
    });

    const showSpinner = () => {
       document.getElementById("spinner")!.hidden = false;
      }
      
    const removeSpinner = () => {
        document.getElementById("spinner")!.hidden = true;
      }
      
    const back = () => {
      if(isdalle2 == 1){
        setisdalle2(0);
        document.getElementById("canvas")!.hidden = true;
      }
      else{
        let tem = images.map((item, index)=>{
            if(index==displayedImage.id){
                return{...item, path: displayedImage.path};
            }
            return {...item};
        });
        setimages(tem);
        navigate('/');
      }
    }

    const addhis = (newimg:any) => {
        const temp:any[][] = [];
        his.map((item:any, index:number)=>{
          temp.push([]);
          for(var i = 0; i<item.length; i++){
            temp[index].push({id: index, path: item[i].path, intro: item[i].intro, critics: item[i].critics});
          }
          if(index == displayedImage.id){
            temp[displayedImage.id].push({id: displayedImage.id, path: newimg,  intro: displayedImage.intro, critics: displayedImage.critics});
          }
        });
        sethis(temp);
    }

    const calldalle1 = async () => {
        
         const localprompt = (document.getElementById("promptbox")! as HTMLFormElement).value;
         //console.log(prompt);
         
         const params ={
            prompt: localprompt,
            image: displayedImage.path
         }

        showSpinner();
        //const response = await axios.post('http://localhost:5000/dalle/transimage', params);
        const response = await axios.post("https://triple-helix-be.onrender.com/dalle/transimage", params);
        const newimg = response.data.data;

        setDisplayedImage({path: newimg, id: displayedImage.id, intro: displayedImage.intro, critics: displayedImage.critics});
        addhis(newimg);
        
        removeSpinner();
        console.log(response);
       /*
       const response = await axios.get('http://localhost:5000/dalle/');
       console.log(response);
       */
    }
    const calldalle2 = async () => {
        const localprompt = (document.getElementById("promptbox")! as HTMLFormElement).value;
        //console.log(prompt);
        /*
        const canvas : any = document.getElementById("Canvas");
        const context = canvas.getContext("2d");
        const imageData = context.getImageData(0,0, canvas.width, canvas.height);
        for(var i = 3; i<imageData.data.length;i+=4){
            if(imageData[i]==1){
                imageData[i-1]=0;
                imageData[i-2]=0;
                imageData[i-3]=0;
            }
            else{
                imageData[i]=1;
                imageData[i-1]=255;
                imageData[i-2]=255;
                imageData[i-3]=255;
            }
        }      
        context.putImageData(imageData, 0 ,0);
        setmaskImage(canvas.toDataURL());
        */
        const params ={
           prompt: localprompt,
           image: displayedImage.path,
           mask: maskImage,
        }
       showSpinner();
       try{
          // const response = await axios.post('http://localhost:5000/dalle/transimage2', params);
           const response = await axios.post('https://triple-helix-be.onrender.com/dalle/transimage2', params);
           const newimg = response.data.data;
    
           setDisplayedImage({path: newimg, id: displayedImage.id, intro: displayedImage.intro, critics: displayedImage.critics});
           addhis(newimg);

           console.log(response);
       }catch(error){
           alert("f2 got some error! Please make sure you have typed in something in the text box below!. Mayby you typed in some sensitive words? Maybe try a different prompt?");
       }
       removeSpinner();
       document.getElementById('canvas')!.hidden = true;
       setisdalle2(0);
      /*
      const response = await axios.get('http://localhost:5000/dalle/');
      console.log(response);
      */
   }
   const calldalle3 = async () => {
       setisdalle3(1);
       const params ={
          prompt: "",
          image: displayedImage.path
       }

      showSpinner();
      try{
       // const response = await axios.post('http://localhost:5000/dalle/transimage3', params);
        const response = await axios.post('https://triple-helix-be.onrender.com/dalle/transimage3', params);
        const newimg = response.data.data;
        setDisplayedImage({path: newimg, id: displayedImage.id, intro: displayedImage.intro, critics: displayedImage.critics});
        addhis(newimg);
      }catch(error){
        alert("f3 got some error!");
        console.log(error);
      }
      
      removeSpinner();
      setisdalle3(0);
   }

   const init = () => {
     setisdalle2(1);
     document.getElementById("canvas")!.hidden = false;
     var isdown = false;
     var canvas : any = document.getElementById("Canvas");
     var context = canvas.getContext("2d"); 
     const image : any = document.getElementById("uploadimage");
     canvas.width = image.width;
     canvas.height = image.height;
     context.drawImage(image, 0 ,0, canvas.width, canvas.height);
      setDisplayedImage({path: canvas.toDataURL(), id: displayedImage.id, intro: displayedImage.intro, critics: displayedImage.critics});
     const imageData = context.getImageData(0,0, canvas.width, canvas.height);
     /*
     for(var i = 3; i<imageData.data.length;i+=4){
        if(i>288000){
        imageData.data[i] = 0;
        }
     }
     */
     console.log(imageData.data);
     context.putImageData(imageData, 0 ,0);
     setmaskImage(canvas.toDataURL());
 
     const handleMouseDown = (e:any) => {
      isdown = true;
      var pos = getMousePos(e);
      console.log("mouse", pos); 
      erase(pos.x, pos.y);  
     }
     const handleMouseUp = (e:any) => {
      isdown = false;
     }
     const handleMouseMove = (e:any) => {
      if(!isdown){
        return;
      }
      var pos = getMousePos(e);
      erase(pos.x, pos.y);
     }
     const erase = (x:number, y:number) => {
        context.beginPath();
        context.fillStyle = "rgba(255,255,255,1)";
        context.globalCompositeOperation = 'destination-out'
        context.arc(x, y, 30, 0, Math.PI*2);
        context.fill();
        //context.clearRect(x, y, 100, 100);
        setmaskImage(canvas.toDataURL());
     }

     const getMousePos = (evt: any) => {
      const rect = canvas.getBoundingClientRect();
      return{
         x: evt.clientX - rect.left,
         y: evt.clientY - rect.top
      };
    }

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
   }



   useEffect (() => {
       console.log("useeffect!");
       setisdalle2(0);
       removeSpinner();
   }, []);


   useEffect(()=>{
    let senddata = {
      "imgs": [displayedImage],
      'update': [displayedImage.id]
    }
     // axios.post("http://localhost:5000/update", senddata)
     axios.post("https://triple-helix-be.onrender.com/update", senddata)
        .then((res) => {
          console.log("post success");
        })
        .catch((error) => {
          throw new Error('axios post error!');
        console.log(error);
         })
   }, [displayedImage])
   

    return(
       <div className = "main">
        <div className = "back">
         <Button variant="outlined" onClick = {back}>
                   Back
        </Button>
        </div>
       
        <div className = "imagebox">
           <div className = "image">
               <img id = "uploadimage" alt={'user inputted'} src={displayedImage.path} width={512}  height={512}  />
           
            { /*
            <div className = "content">
               <p>Please upload the image here! </p>
               <label htmlFor="contained-button-file">
               <Input onChange={updateImages}  id="contained-button-file" type="file" />
               <Button variant="outlined" component="span" > Upload </Button>
               </label> 
            </div>
            */}

            </div>
            <div className = "canvas" id = "canvas" hidden>
                <canvas id="Canvas"></canvas>
                {
                isdalle2 == 1 && 
                <div className = "edit">
                <img src = "./editme.png" width = "300px" height = "80px"/> 
                </div>    
                }
            </div>
        </div>
 
            <div className="spinner" id ="spinner"> <CircularProgress /></div>
            <div className = "prompt">
            <div>
              {
                isdalle2 == 1 &&
                <p>The image on the left is the original image.  You can change the image on the right by moving your mouse over the area you want to replace with an created out of a prompt you will enter in the box below.</p>
              }
              <p><strong>{displayedImage.intro}</strong></p>
              <p>{displayedImage.critics}</p>
            </div>
              {isdalle2==1 &&
               <TextField
                    fullWidth
                    id="promptbox"
                    multiline
                    rows = {2}
                />
              }
            </div>
                {  isdalle2==0 && isdalle3 == 0 &&
                 <div className = "buttonbox">
                  {/*
                    <Button variant="outlined" component="span" onClick = {calldalle1} > F1 </Button>
                */}
                    <Button variant="outlined" component="span" onClick = {init} > CHANGE BY MASK </Button>
                    <Button variant="outlined" component="span" onClick = {calldalle3} > VARIATION </Button>
                    <Button variant="outlined" component="span" >
                       <Link to = "/history"  style={{ color: 'inherit', textDecoration: 'inherit'}}>
                          History
                       </Link>            
                    </Button>
                 </div>   
                }
                {
                    isdalle2 == 1 &&
                 <div className = "buttonbox">
                    <Button variant="outlined" component="span" onClick = {calldalle2} > Confirm </Button>
                 </div>   
                }
                 
       </div>
    );
}

export default Manage;
