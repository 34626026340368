import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Manage from "./components/manage/manage";
import Imageselect from "./components/imageselect/imageselect";
import History from "./components/history/history";
import P1 from "./components/paintings/p1/p1";
import P2 from "./components/paintings/p2/p2";
import P3 from "./components/paintings/p3/p3";
import P4 from "./components/paintings/p4/p4";
import P5 from "./components/paintings/p5/p5";
import P6 from "./components/paintings/p6/p6";
import './App.css';
import {RecoilRoot} from "recoil";
import socketIO from 'socket.io-client';
//const socket = socketIO('http://localhost:5000');
const socket = socketIO('https://triple-helix-be.onrender.com');

function App() {
  return (
    <div className="App">
      <RecoilRoot>
          <Routes>
            <Route path = "/" element = {<Imageselect/>}/>
            <Route path = "/manage" element = {<Manage/>}/>
            <Route path = "/history" element = {<History/>}/>
            <Route path = "/p1" element = {<P1 socket = {socket}/>}/>
            <Route path = "/p2" element = {<P2 socket = {socket}/>}/>
            <Route path = "/p3" element = {<P3 socket = {socket}/>}/>
            <Route path = "/p4" element = {<P4 socket = {socket}/>}/>
            <Route path = "/p5" element = {<P5 socket = {socket}/>}/>
            <Route path = "/p6" element = {<P6 socket = {socket}/>}/>
          </Routes>
      </RecoilRoot>
    </div>
  );
}

export default App;
