import "./history.css";
import React from "react";
import {useState, useEffect, useRef} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import { currentimage, imagelist, historyimages } from "../../store";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

const History = () => {
    const his = useRecoilValue(historyimages);
    const [cur, setcur] = useRecoilState(currentimage);
    const [select, setselect] = useState(-1);

    const choosehis = ( value: any, index: number) => {

        var originalbutton = document.getElementById(select.toString());
        if(originalbutton){
           originalbutton.style.backgroundColor = "";
        }
        setselect(index);
   
        setcur({path: value.path, id: value.id, intro: value.intro, critics: value.critics});
   
        var button = document.getElementById(index.toString());
        if(button){
        button.style.backgroundColor = "black";
        }
       }

    return(
      <div className = "history">
        <div className = "images">
           { 
            his[cur.id].map((value, index) => {
                return (   
                  <Button className = "space" id = {index.toString()} onClick = {() => choosehis(value, index)}>
                     <img src = {value.path} height = "300px" width = "300px"/>
                  </Button>  ) }
            )}
        </div>
        <div className = "buttonspace">
        <Button variant="outlined">
           <Link to = "/manage"  style={{ color: 'inherit', textDecoration: 'inherit'}}>
              Confirm
           </Link>
        </Button> 
        </div>
      </div>     
    );
}

export default History;