import {atom, selector} from "recoil";

export const currentimage = atom({
    key:"current-image",
    default: {
        path: "0.png",
        id: 0,
        intro: "",
        critics: "",
    }
})

export const imagelist = atom<any[]>({
    key:"image-list",
    default: [{
        path: "",
        id: -1,
        intro:"",
        critics: "",
    }]
})

export const locker = atom({
    key: "locker",
    default: true,
})

export const historyimages = atom<any[][]>({
    key:"history",
    default: [[{
            path: "",
            id: -1,
            intro:"",
            critics: "",
             }]]
})