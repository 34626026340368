import "./imageselect.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Images from "../../database/images.json";
import { currentimage, imagelist, locker, historyimages } from "../../store";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

const Imageselect = () => {
  const [selectedimage, setselectedimage] = useRecoilState(currentimage);
  const [images, setimages] = useRecoilState(imagelist);
  const [shouldeffect, setshouldeffect] = useRecoilState(locker);
  const [his, sethis] = useRecoilState(historyimages);
  const [resetclicked, setresetclicked] = useState(false);


  const reset = async () => {
    console.log("reset!");
    alert("Now you have ended your journey, please fill out the audience study form! Thank you!!!");
    setresetclicked(true);
    try{
      // var response = await axios.get("http://localhost:5000/init");
      var response = await axios.get("https://triple-helix-be.onrender.com/init");
    }catch(rej){
      throw new Error('axios post error!');
      console.log("axios error", rej);
    };

    const imagejson = response.data;
    await setimages(imagejson);
    var originalbutton = document.getElementById(selectedimage.id.toString());
    if (originalbutton) {
      originalbutton.style.backgroundColor = "";
    }
    setselectedimage({ path: "0.png", id: 0, intro: "", critics: ""});
    const temp: any[][] = [];
    imagejson.map((item: any, index: number) => {
      temp.push([]);
      temp[index].push({ id: index, path: item.path, intro: item.intro, critics: item.critics });
    });
    sethis(temp);
  };

  const chooseimage = (value: any, index: number) => {
    var originalbutton = document.getElementById(selectedimage.id.toString());
    if (originalbutton) {
      originalbutton.style.backgroundColor = "";
    }

    setselectedimage({ path: value.path, id: index, intro: images[index].intro, critics: images[index].critics });

    var button = document.getElementById(index.toString());
    if (button) {
      button.style.backgroundColor = "black";
    }
  };

  useEffect(() => {
    const init = async () => {
      console.log("init!");
      try{
       //var response = await axios.get("http://localhost:5000/init")
       var response = await axios.get("https://triple-helix-be.onrender.com/init");
      }catch(rej){
        throw new Error('axios post error!');
        console.log("axios error", rej);
      };
      const imagejson = response.data;
      await setimages(imagejson);
      const temp: any[][] = [];
      imagejson.map((item: any, index: number) => {
        temp.push([]);
        temp[index].push({ id: index, path: item.path, intro: item.intro, critics: item.critics});
      });
      sethis(temp);
      setshouldeffect(false);
    };
    if (shouldeffect) {
      init();
      console.log("rendered!");
    }
  }, []);

  useEffect(()=>{
    if(!shouldeffect){
      console.log("upload");
      if(resetclicked){
        let senddata = {
          'imgs': [],
          'update': new Array()
        }
        console.log('reset', senddata);
        images.map((item:any, index:number)=>{
         if(index!=0){
          senddata.update.push(index);
         }
        })
        /*
        axios.post("http://localhost:5000/update", senddata)
          .then((res) => {
            console.log("post success");
          })
          .catch((error) => {
            throw new Error('axios post error!');
          console.log(error);
           })
           */
        axios.post("https://triple-helix-be.onrender.com/update", senddata)
        .then((res) => {
          console.log("post success");
        })
        .catch((error) => {
          throw new Error('axios post error!');
        console.log(error);
         })  
        setresetclicked(false);
       
      }
      /*
      else{
        let senddata = {
          'imgs': [selectedimage],
          'update': [selectedimage.id],
        }      
        axios.post("http://localhost:5000/update", senddata)
        .then((res) => {
          console.log("post success");
        })
        .catch((error) => {
          throw new Error('axios post error!');
        console.log(error);
         })
     }*/
    }
  }, [images])

  return (
    <div className="imagelibrary">
      <div className="image-database">
        {images.map((value, index) => {
          if (index != 0)
            return (
              <Button
                className="space"
                id={index.toString()}
                onClick={() => chooseimage(value, index)}
              >
                <img src={value.path} height="350px" width="350px" />
              </Button>
            );
        })}
      </div>

      <div className="reset">
        <Button variant="outlined" onClick={reset}>
          Exit
        </Button>
      </div>

      <div className="confirm-box">
        {selectedimage.path != "0.png" && 
        <div className = "intro">
          <p><strong>{selectedimage.intro}</strong></p>
          </div>}
        {selectedimage.path == "0.png" ? (
          <img
            className="downspace"
            src={"./original/0.png"}
            height="300px"
            width="300px"
          />
        ) : (
          <img
            className="downspace"
            src={selectedimage.path}
            height="300px"
            width="300px"
          />
        )}
        {selectedimage.path == "0.png" ? (
          <p><strong>Please Select Image</strong></p>
        ) : (
          <p>{selectedimage.critics}</p>
        )}
        { selectedimage.id != 0 &&
        <Button variant="contained">
          <Link
            to="/manage"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Confirm
          </Link>
        </Button>
        }
      </div>
    </div>
  );
};
export default Imageselect;
