import "./p4.css";
import React from "react";
import {useState, useEffect, useRef} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import { currentimage, imagelist, historyimages } from "../../../store";

const P4 = (input: any) => {
    const [test, settest] = useState("http://localhost:5000/image4.png");
    const [num, setnum] = useState(0);
    const [firsttime, setfirsttime] = useState(true);

    input.socket.emit('setpainting', {"id": 4});
    input.socket.on('refresh', (msg: string) => {
        setnum(111);
    })

    useEffect (() => {
        if(!firsttime){
        window.location.reload();
        console.log('refresh');
        }
        setfirsttime(false);
    },[num])

    return(
        <div className = "painting">  
            <img  src={test} height="1080px" width="1080px" />  
        </div>
    )
}

export default P4;